<template>
    <div
        v-show="isShowBox"
        ref="myDialog"
        class="my-dialog"
        :class="[isShow ? 'box-in' : 'box-out', theme]"
        tabindex="0"
        @keydown.enter="confirm"
        @keydown.esc="cancel"
    >
        <div
            v-if="isShowBox"
            class="main"
            id="main"
            :class="[isShow ? 'dialog-in' : 'dialog-out']"
            :style="dialogStyle"
            @click.stop
            @animationend.stop="toggleShowBox(isShow)"
        >
            <div class="head">
                <div class="left">{{ title }}</div>
                <div class="right" @click.stop.prevent="cancel(0)">×</div>
            </div>
            <div class="center" ref="center" :style="centerStyle">
                <div class="center-content">
                    <slot></slot>
                </div>
            </div>
            <slot name="footer">
                <div class="footer">
                    <button
                        class="item cancel"
                        :style="cancelHoverStyle"
                        @mouseover="toggleCancelState(true)"
                        @mouseout="toggleCancelState(false)"
                        @click.stop.prevent="cancel"
                        v-if="!buttonConfig[0].hidden"
                    >
                        {{ buttonConfig[0].text }}
                    </button>
                    <button class="item confirm" :style="themeBgColor" @click.stop="confirm" v-if="!buttonConfig[1].hidden">
                        <div>{{ buttonConfig[1].text }}</div>
                        <div class="mask"></div>
                    </button>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "NativeDialog",
    data() {
        return {
            isCancelHover: false,
            isShowBox: false,
        };
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        themeColor: {
            type: String,
            default: "40, 33, 252",
        },
        buttonConfig: {
            type: Array,
            default: () => [{ text: "取消" }, { text: "确定" }],
        },
        width: {
            type: Number,
            default: 520,
        },
        cancelHandler: {
            type: Function,
            default() {
                return () => this.cancel();
            },
        },
        confirmHandler: {
            type: Function,
            default() {
                return () => {};
            },
        },
        top: {
            type: Number,
            default: 30,
        },
        padding: {
            type: String,
            default: "40px 0",
        },
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        themeRgb() {
            return this.themeColor.split(",").map((item) => item.trim() * 1);
        },
        themeBgColor() {
            return {
                backgroundColor: `rgb(${this.themeColor})`,
            };
        },
        dialogStyle() {
            return {
                top: `${this.top}%`,
                width: `${this.width}px`,
            };
        },
        cancelHoverStyle() {
            if (!this.isCancelHover) {
                return {};
            }
            let { themeColor } = this;
            return {
                color: `rgb(${themeColor})`,
                backgroundColor: `rgba(${themeColor}, .1)`,
                border: `1px solid rgba(${themeColor}, .3)`,
            };
        },
        centerStyle() {
            return {
                padding: this.padding,
            };
        },
        theme() {
            return localStorage.getItem("role") == 5 ? "black" : "";
        },
    },
    watch: {
        isShow(newVal, oldVal) {
            if (newVal) {
                this.isShowBox = newVal;
                this.$nextTick(() => this.$refs.myDialog && this.$refs.myDialog.focus());
            }
        },
    },
    methods: {
        toggleShowBox(state) {
            this.isShowBox = state;
            if (!state) {
                this.isCancelHover = this.isCancelHover ? false : this.isCancelHover;
                // this.cancelHandler();
                this.$emit("closeDialog");
            }
        },
        confirm() {
            this.confirmHandler();
        },

        cancel(origin = 1) {
            this.$emit("update:isShow", false);
            origin && this.cancelHandler();
        },
        toggleCancelState(state) {
            this.isCancelHover = state;
        },
    },
};
</script>

<style scoped>
@keyframes boxIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes boxOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes dialogIn {
    0% {
        transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes dialogOut {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) scale(0.9);
    }
}
.dialog-in {
    animation: dialogIn 0.3s ease 0s 1 alternate both running;
}
.dialog-out {
    animation: dialogOut 0.3s ease 0s 1 alternate both running;
}
.box-in {
    animation: boxIn 0.3s ease 0s 1 alternate both running;
}
.box-out {
    animation: boxOut 0.3s ease 0s 1 alternate both running;
}
</style>

<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
}
.my-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999999;
    transition: all 0.3s;
    cursor: default;
    user-select: none;
    .main {
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        .head {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px 0;
            font-size: 16px;
            color: #060111;
            .left {
                display: flex;
                align-items: center;
                font-weight: 700;
            }
            .left::before {
                display: block;
                content: "";
                width: 4px;
                height: 12px;
                margin-right: 10px;
                background-color: #2821fc;
            }
            .right {
                position: absolute;
                right: 20px;
                top: 70%;
                transform: translateY(-50%);
                font-size: 22px;
                cursor: pointer;
            }
        }
        .center {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            /* margin: 20px 0; */
            min-height: 100px;
            /* padding: 15px 0 0px; */
            text-align: center;
            .center-content {
                width: 100%;
                color: #1b162a;
                font-size: 14px;
            }
        }
        .footer {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            /* margin-top: 20px; */
            .item {
                padding: 10px 25px;
                margin-right: 15px;
                font-size: 14px;
                border: none;
                border-radius: 5px;
                background-color: #fff;
                cursor: pointer;
                transition: all 0.3s;
            }
            .item:last-child {
                margin-right: 0;
            }
            .confirm {
                position: relative;
                color: #fff;
            }
            .confirm:hover .mask {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                transition: all 0.3s;
                background-color: rgba(255, 255, 255, 0.2);
            }
            .cancel {
                color: #555;
                border: 1px solid #ccc;
                background-color: transparent;
            }
        }
    }
}

.black {
    #main {
        background-color: #0c0e3f;
        .head .left,
        .head .right,
        .center-content {
            color: #fff;
        }
        .center-content {
            padding: 0 30px;
            text-align: start;
        }
        .footer {
            .confirm {
                background-color: #4b45ff;
            }
            .cancel {
                color: #fff;
            }
        }
    }
}
</style>

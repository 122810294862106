<template>
    <div class="modal">
        <div class="mask"></div>
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyModal",
    data() {
        return {};
    },
    computed: {},
    components: {},
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.modal {
    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 99;
    }
    .container {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 380px;
        background-color: #fff;
        border-radius: 20px;
        z-index: 999;
    }
}
</style>

<template>
    <div class="live-modal" v-if="isShow">
        <div class="container">
            <hint-dialog>
                <div class="hint-icon iconfont">&#xe66b;</div>
                <div class="content">
                    <div class="title">温馨提示</div>
                    <div class="center">
                        <slot></slot>
                    </div>
                    <div class="button-list">
                        <div class="left item" @click="cancel">{{ buttonConfig[0] }}</div>
                        <div class="line"></div>
                        <div class="right item" @click="confirmHandler">{{ buttonConfig[1] }}</div>
                    </div>
                </div>
            </hint-dialog>
        </div>
    </div>
</template>

<script>
import HintDialog from "./HintDialog";
export default {
    name: "LiveModal",
    data() {
        return {};
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        buttonConfig: {
            type: Array,
            default: () => ["我再想想", "暂停直播"],
        },
        confirmHandler: {
            type: Function,
            default: () => {},
        },
    },
    computed: {},
    components: {
        HintDialog,
    },
    methods: {
        cancel() {
            this.$emit("update:isShow", false);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-modal {
    .container {
        .hint-icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 140px;
            height: 140px;
            line-height: 140px;
            text-align: center;
            font-size: 110px;
            color: #ff821e;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px 8px 46px 0px rgba(255, 130, 30, 0.08);
        }
        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border;
            .title {
                margin: 110px 0 40px;
                font-size: 23px;
                text-align: center;
                color: #070932;
                font-weight: 700;
            }
            .center {
                padding: 0 64px;
                flex: 1;
                font-size: 15px;
                color: #060111;
                line-height: 30px;
                letter-spacing: 1px;
            }
            .button-list {
                display: flex;
                border-top: 1px solid #ddd;
                border-radius: 0 0 20px 20px;
                overflow: hidden;
                .item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 58px;
                    font-size: 15px;
                    color: #060111;
                    cursor: pointer;
                    transition: all 0.2s;
                    &:hover {
                        color: #fff;
                        background-color: #4b45ff;
                    }
                }
                .line {
                    width: 1px;
                    background-color: #ddd;
                }
                .right {
                    color: #4b45ff;
                }
            }
        }
    }
}
</style>

<template>
    <div class="lucky-bag">
        <div class="container">
            <my-popup :title="title" :is-show="isShow" @leftHandler="toggleShowPopup(false)" @animateEnd="animateEnd">
                <div class="content">
                    <div class="lucky-bag-item" v-for="item in list" :key="item.id">
                        <div class="lucky-bag-info">
                            <div class="left-img-box">
                                <img class="lucky-img" src="~assets/images/live/lucky-bag.png" alt="福袋" title="福袋" />
                            </div>
                            <div class="right-info">
                                <div class="info-item title">{{ item.bag_name }}</div>
                                <div class="info-item scope">参与范围：{{ scopes[item.in_type - 1] }}</div>
                                <div class="info-item people-num">可中奖人：{{ item.winning_number }}人</div>
                                <div class="info-item word-order">参与口令：关注加粉丝团领取超值福袋</div>
                                <div class="info-item count-down">倒计时：{{ item.countdown }}s</div>
                            </div>
                        </div>
                        <div class="actions-button">
                            <el-button class="item not-send" v-if="item.status == 0" @click="release(item)">发送福袋</el-button>
                            <el-button class="item already-send" v-else>已发送</el-button>
                        </div>
                    </div>
                </div>
            </my-popup>
        </div>
    </div>
</template>

<script>
import MyPopup from "./containers/MyPopup";
export default {
    name: "LiveLuckyBag",
    data() {
        return {
            scopes: ["所有观众", "仅限粉丝"],
            // luckyBagImg: require("")
        };
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        title() {
            return `发布福袋 (${this.list.length})`;
        },
    },
    components: {
        MyPopup,
    },
    methods: {
        toggleShowPopup(state) {
            this.$emit("update:isShow", state);
        },
        release(item) {
            this.$emit("release", { check: "bag", item });
        },
        animateEnd() {
            this.isShow || this.$emit("animateEnd");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.lucky-bag {
    width: 100%;
    .container {
        .content {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .lucky-bag-item {
                box-sizing: border-box;
                padding: 20px 30px;
                border-bottom: 1px solid #ddd;
                .lucky-bag-info {
                    display: flex;
                    align-items: center;

                    .left-img-box {
                        width: 90px;
                        height: 95px;
                        .lucky-img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .right-info {
                        flex: 1;
                        margin: 0 46px 20px;
                        font-size: 16px;
                        color: #5c5c6f;
                        overflow: hidden;
                        .info-item {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .title {
                            margin-bottom: 20px;
                            font-size: 18px;
                            color: #1b162a;
                        }
                    }
                }
                .actions-button {
                    text-align: center;
                    .item {
                        box-sizing: border-box;
                        width: 130px;
                        height: 47px;
                        font-size: 16px;
                        letter-spacing: 2px;
                        border-radius: 6px;
                    }
                    .not-send {
                        color: #fff;
                        background-color: #ed2612;
                        border: 2px solid #e8b579;
                    }
                    .already-send {
                        color: #cc855c;
                        background-color: #fbdebc;
                        cursor: default;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="live-end">
        <div class="container">
            <hint-dialog>
                <div class="hint">直播结束</div>
                <div class="time">{{ formatTime }}</div>
                <div class="avatar">
                    <img class="avatar-img" :src="userInfo.avatar || avatar" alt="user" title="user" />
                </div>
                <div class="username" v-if="userInfo">{{ userInfo.nickname }}</div>
                <div class="button-list">
                    <el-button class="view-other-live" @click="back">返回首页</el-button>
                    <!-- <el-button class="view-replay" @click="toReview">查看复盘</el-button>
                    <el-button class="view-other-live" @click="toLiveRoomList">查看他人直播</el-button> -->
                </div>
            </hint-dialog>
        </div>
    </div>
</template>

<script>
import HintDialog from "./containers/HintDialog";
export default {
    name: "LiveEndCenter",
    data() {
        return {
            avatar: require("assets/images/user_img.png"),
        };
    },
    computed: {},
    components: {
        HintDialog,
    },
    props: {
        userInfo: {
            type: Object,
            default: () => null,
        },
        liveDuration: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        formatTime() {
            const totalSecond = this.liveDuration;
            const getValue = (value) => ((value + "").length <= 1 ? `0${value}` : value);
            let hour = getValue(Math.floor(totalSecond / 60 / 60));
            let minute = getValue(Math.floor(totalSecond / 60) % 60);
            let second = getValue(totalSecond % 60);
            return [hour, minute, second].join(":");
        },
    },
    methods: {
        toReview() {
            this.$router.push("/liveReplay");
        },
        toLiveRoomList() {
            this.$router.push("/otherLiveRoomList");
        },
        back() {
            this.$router.back();
        }
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-end {
    flex: 1;
    display: flex;
    .container {
        .hint {
            font-size: 18px;
        }
        .time {
            margin: 20px 0 50px;
            font-size: 36px;
            font-weight: 700;
        }
        .avatar {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            overflow: hidden;
            .avatar-img {
                width: 100%;
                height: 100%;
            }
        }
        .username {
            margin: 24px 0 27px;
            font-size: 16px;
        }
        .button-list {
            height: 40px;
            .view-replay,
            .view-other-live {
                height: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #fe3266;
            }
            .view-other-live {
                background-color: #4b45ff;
            }
        }
    }
}
</style>
